<template>
  <div class="mt-3 w-full mb-4">
    <div class="ion-no-padding ion-no-margin relative ion-text-left" >
      <p class="text-s">{{label}}</p>

<!--      :class="(v$ && v$[form][name].$invalid && v$[form][name].$dirty) ? 'error-border' : ''"-->
      <v-date-picker
          :input-debounce="500"
          :masks="masks"
          :max-date="maxDate"
          :min-date="minDate"
          :locale="datepickerLocale"
          class="inline-block w-full custom-date-picker rounded-sm relative mt-1"
          v-model="fieldData">
        <template v-slot="{ inputValue, inputEvents, togglePopover }">
          <div class="relative cursor-pointer">
            <input
                v-on="inputEvents"
                :value="inputValue"
                :placeholder="placeholder"
                class="bg-transparent h-full cursor-pointer w-full text-sm"
            />
            <ion-icon :icon="calendarOutline" @click="togglePopover()" />
          </div>
        </template>
      </v-date-picker>

    </div>
    <div class="error" v-if="v$ && v$[form][name].$invalid && v$[form][name].$dirty">
      <div v-for="(error, index) in v$[form][name].$errors" :key="index">
        {{$t(validationErrors[error.$validator])}}
      </div>
    </div>

    <div class="error" v-if="vBe">
      <div v-for="(error, index) in vBe[name]"  :key="index">
        {{displayError(error)}}
      </div>
    </div>
  </div>
</template>
<script>

import utils from '@/plugins/w/custom-toast/utils'
import { calendarOutline } from 'ionicons/icons'
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: null,
    label: {
      required: false,
      default: ''
    },
    name: {
      required: false,
      default: ''
    },
    locale: {
      required: false,
      default: 'sk-SK'
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    vBe: {
      type: Array,
      required: false,
    },
    form: {
      type: String,
      default: 'form'
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    v$: {
      default: null
    },
  },
  mounted() {
    this.fieldData = this.modelValue
    if (this.locale) {
      this.datepickerLocale = this.locale.replace('_', '-')
    }
  },
  watch: {
    modelValue(data) {
      this.fieldData = data
    },
    fieldData(data) {
      this.$emit('update:modelValue', data)
    }
  },
  data() {
    return {
      calendarOutline,
      fieldData: null,
      masks: {
        input: 'DD.MM.YYYY',
      },
      validationErrors: {
        required: 'Toto pole je povinné.',
      },
      datepickerLocale: 'sk-SK'
    }
  },
  methods: {
    displayError(error) {
      return utils.parseToString(error)
    }
  }

}
</script>
<style lang="sass" scoped>
.error-border
  border: 1px solid red !important

.custom-date-picker
  border: 1px solid #DCE1E9
  height: 42px
  line-height: 40px
  padding-left: 10px

  &:hover
    border: 1px solid #298CF0

  input
    border: none
    outline: none
    height: 90%
    background: transparent

  ion-icon
    position: absolute
    right: 5px
    top: 10px
</style>
