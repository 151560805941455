import router from '@/router'
import { App } from '@capacitor/app'

// PAUSE
let checkForResumeApp
document.addEventListener('pause', () => {
  checkForResumeApp = new Date().getTime()
})

// RESUME
document.addEventListener('resume', async () => {
	const now = new Date().getTime()
		const diff = now - checkForResumeApp

		if (diff < 60000) {
			return
		}

  const userToken = localStorage.getItem('wzo_vue_user_token')

  if (!userToken) {
    return
  }

  const route = router.currentRoute.value

  // Pages that start with '/auth' are pages like register, login etc.
  const isAuthRoute = !route.path.startsWith('/auth') 

  if(isAuthRoute) {
    const currentRoute = route.fullPath
    const currentUser = JSON.parse(localStorage.getItem('wzo_vue_user_user'))
    currentUser['lastVisitedRoute'] = currentRoute

    localStorage.setItem('wzo_vue_user_user', JSON.stringify(currentUser))
  }

  router.push('/auth/pin')
})

// STATE CHANGED
App.addListener('appStateChange', (state) => {
    const body = document.querySelector('body')

    if (state.isActive) {
        body?.classList.remove('blur-active')
        body?.classList.add('blur-inactive')
    }

    if (!state.isActive) {
        body?.classList.add('blur-active')
        body?.classList.remove('blur-inactive')
    }

})
