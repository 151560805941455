import VueTippy from 'vue-tippy'
import { roundArrow } from 'vue-tippy'
import 'tippy.js/animations/scale.css'

const wezeoVueTippyDefaultObject = VueTippy

// Config for Vue tippy object 
// For all props see:
// https://vue-tippy.netlify.app/props
const wezeoVueTippyConfig = {
	directive: 'tippy', // => v-tippy
	component: 'tippy', // => <tippy/>
	componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
	defaultProps: {
		placement: 'bottom',
		arrow: roundArrow,
		delay: [100, 100],
		theme: 'custom-theme',
		animation: 'scale',
		touch: false,
		trigger: 'mouseenter',
	},
}

export const wezeoTippy = [wezeoVueTippyDefaultObject, wezeoVueTippyConfig] as const