import axios from 'axios'
import { Subject } from 'rxjs'
import { getNetworkStatus } from '@/plugins/w/offline-detection'

class Maintenance {

  hook = new Subject<number>();

  constructor() {
      axios.interceptors.response.use(
          (response) => response,
          (err) => this._catch(err)
      )
  }

  private async _catch(error) {
    const status = await getNetworkStatus()

    if (!error.response || error.response.status != 503 || !status.connected) {
      return Promise.reject(error)
    }

    this.hook.next(error.response.data.error)
  }
}


export default new Maintenance()
