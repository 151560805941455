import { App } from '@capacitor/app'
import store from '@/store'
import wToast from '@/plugins/w/toast'
import i18n  from '@/plugins/w/translate/index.js'


export function initDeeplinks(router) {
	App.addListener('appUrlOpen', async (data) => {
		if (data.url?.includes('auth/activation')) {
			const params = data.url.split('&email=')
			const mail = params[1]
			const code = params[0].split('code=')[1]
			try {
				await store.dispatch('wAuth/verify', { code: code, email: mail })
				window.open(location.origin + '/auth/login?showActivation=true', "_self")
			} catch (e) {
				wToast.error(i18n.global.t('Nastala neočakávaná chyba.'))
			}
		}
		const url = data.url.replace('https://app.finbot.eu', '')

		if (url) {
			router.push(url)
		}
	})
}
