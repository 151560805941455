//Components
import {
	IonApp,
	IonButtons,
	IonMenuButton,
	IonPage,
	IonContent,
	IonFooter,
	IonButton,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonInput,
	IonIcon,
	IonImg,
	IonRow,
	IonCol,
	IonItem,
	IonLabel,
	IonGrid,
	IonText,
	IonCheckbox,
	IonMenu,
	IonSelect,
	IonList,
	IonSelectOption,
	IonRouterOutlet,
	IonMenuToggle,
	IonDatetime,
	IonToggle,
	IonSkeletonText,
	IonRadioGroup,
	IonRadio
} from '@ionic/vue'

import { App } from 'vue'

export const registerIonicComponents = (app: App<Element>) => {

	app.component('ion-app', IonApp)
	app.component('ion-page', IonPage)
	app.component('ion-content', IonContent)
	app.component('ion-footer', IonFooter)
	app.component('ion-button', IonButton)
	app.component('ion-buttons', IonButtons)
	app.component('ion-menu-button', IonMenuButton)
	app.component('ion-header', IonHeader)
	app.component('ion-input', IonInput)
	app.component('ion-select', IonSelect)
	app.component('ion-select-option', IonSelectOption)
	app.component('ion-checkbox', IonCheckbox)
	app.component('ion-toggle', IonToggle)
	app.component('ion-title', IonTitle)
	app.component('ion-toolbar', IonToolbar)
	app.component('ion-icon', IonIcon)
	app.component('ion-text', IonText)
	app.component('ion-img', IonImg)
	app.component('ion-grid', IonGrid)
	app.component('ion-row', IonRow)
	app.component('ion-col', IonCol)
	app.component('ion-item', IonItem)
	app.component('ion-label', IonLabel)
	app.component('ion-menu', IonMenu)
	app.component('ion-menu-toggle', IonMenuToggle)
	app.component('ion-list', IonList)
	app.component('ion-skeleton-text', IonSkeletonText)
	app.component('ion-datetime', IonDatetime)
	app.component('ion-router-outlet', IonRouterOutlet)
	app.component('ion-radio-group', IonRadioGroup)
	app.component('ion-radio', IonRadio)
}
