import wAxios from '@/plugins/w/axios'
import {Storage} from '@capacitor/storage'

export default {
	namespaced: true,
	state: {
		status: '',
		_token: localStorage.getItem('wzo_vue_user_token') || '',
		user: JSON.parse(localStorage.getItem('wzo_vue_user_user') || '{}'),
		trustedBrowser: null
	},
	mutations: {
		auth_request(state: any) {
			state.status = 'loading'
		},
		auth_success(state: any, data: any) {
			state.status = 'success'
			if (data.token) {
				state._token = data.token
				localStorage.setItem('wzo_vue_user_token', data.token)
			}

			if (data.user) {
				state.user = data.user
				localStorage.setItem('wzo_vue_user_user', JSON.stringify(data.user))
			}

			if (data.browser != undefined) {
				state.trustedBrowser = data.browser
			}
		},
		auth_error(state: any) {
			state.status = 'error'
			state._token = ''
			localStorage.removeItem('wzo_vue_user_token')
			localStorage.removeItem('wzo_vue_user_user')
			Storage.remove({ key: 'userPin' })
			Storage.remove({ key: 'biometricAuthAllowed' })
		},
		logout(state: any) {
			state.status = ''
			state._token = ''
			state.user = null
			localStorage.removeItem('wzo_vue_user_token')
			localStorage.removeItem('wzo_vue_user_user')
			Storage.remove({ key: 'userPin' })
			Storage.remove({ key: 'biometricAuthAllowed' })
		}
	},
	actions: {
		login({commit}: {commit: any}, credentials: any) {
			return new Promise((resolve, reject) => {
				commit('auth_request')

				wAxios.post_data('v1/auth/login', credentials, { skipAuthRefresh: true })
					.then((response: any) => {
						const token = response.data.response.token
						const user = response.data.response.user
						let browser = null
						if (response.data.response.browser) {
							browser = response.data.response.browser?.is_trusted
						}

						commit('auth_success', {
							token: token,
							user: user,
							browser: browser
						})

						resolve(response)
					}).catch(error => {
						commit('auth_error')
						reject(error)
					})
			})
		},
		signup({commit }: {commit: any}, credentials: any) {
			return new Promise((resolve, reject) => {
				commit('auth_request')

				wAxios.post_data('v1/auth/signup', credentials)
					.then((response: any) => {
						const token = response.data.response.token
						const user = response.data.response.user

						commit('auth_success', {
							token: token,
							user: user
						})

						resolve(response)
					}).catch(error => {
						commit('auth_error')
						reject(error)
					})
			})
		},
		verify({commit }: {commit: any}, credentials: any) {
			return new Promise((resolve, reject) => {
				wAxios.post_data('v1/auth/verify', credentials)
					.then((response: any) => {
						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},
		resend({commit }: {commit: any}, credentials: any) {
			return new Promise((resolve, reject) => {
				wAxios.post_data('v1/auth/verify/resend', credentials)
					.then((response: any) => {
						resolve(response)
					}).catch(error => {
						reject(error)
					})
			})
		},
		logout({commit}: {commit: any}) {
			return new Promise((resolve) => {
				wAxios.post_auth_data('v1/auth/invalidate')
					.finally(() => {
						commit('logout')
						resolve(null)
					})
			})
		},
		forgotPassword({commit}: {commit: any}, credentials: any) {
			return new Promise((resolve, reject) => {
				wAxios.post_auth_data('v1/auth/forgot', credentials)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		resetPassword({commit}: {commit: any}, credentials: any) {
			return new Promise((resolve, reject) => {
				wAxios.post_auth_data('v1/auth/reset', credentials)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		userInfo({commit}: {commit: any}) {
			return new Promise((resolve, reject) => {
				wAxios.get_auth_data('v1/auth/info')
					.then((response: any) => {
						
						const user = response.data.response.user

						commit('auth_success', {
							user: user
						})

						resolve(user)
					}).catch(error => {
						reject(error)
					})
			})
		},
		updateUserInfo({commit}: {commit: any}, data: any) {
			return new Promise((resolve, reject) => {
				wAxios.post_auth_data('v1/auth/info', data)
					.then((response: any) => {
						const user = response.data.response.user

						commit('auth_success', {
							user: user
						})

						resolve(user)
					}).catch(error => {
						reject(error)
					})
			})
		},
	},
	getters: {
		isTrustedBrowser: (state: any) =>  {
			return state.trustedBrowser
		},
		isLoggedIn: (state: any) =>  {
			return !!state._token
		},
		isActivated: (state: any) =>  {
			return state.user.is_activated
		},
		authStatus: (state: any) => {
			return state.status
		},
		user: (state: any) => {
			return state.user
		},
		token: (state: any) => {
			return state._token
		}
	}
}
