//
import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

//
import { IonicVue } from '@ionic/vue'
app.use(IonicVue, { mode: 'ios' })


//
import router from './router'
app.use(router)

//
import { initDeeplinks } from '@/plugins/w/deeplinks'
initDeeplinks(router)

//
import ExponeaTracking from '@/plugins/w/exponea'
ExponeaTracking.init()
//
// import WezeoSentry from '@/plugins/w/sentry'
// WezeoSentry.init(app)

import Notifications from '@/plugins/app/notifications'
Notifications.init()
// loggly
// import { install } from '@/plugins/w/loggly'
//
// export const wLogger = install(
//     {
//       subdomain: 'wezeo',
//       tags: ['FINBOT'],
//       handleExceptions: false,
//       json: true
//     },
//     'Finbot'
// )
//
// app.config.globalProperties.$wLogger = wLogger
// wLogger.info('testing finbot app')

//
import WezeoInAppPurchase from '@/plugins/w/in-app-purchases'
WezeoInAppPurchase.init()

//
import store from './store'
app.use(store)

// GOOGLE ANALYTICS
import GA from '@/plugins/w/vue3-g-tag'
GA.setupGoogleAnalytics(app, router)

//
import '@/plugins/lib@screen/maintenance/maintnance.ts'

//
import VCalendar from 'v-calendar'
app.use(VCalendar, {})

// Vue Tooltips
import { wezeoTippy } from "@/plugins/w/vue-tippy"
app.use(...wezeoTippy)

/* Add ionic components */
import {registerIonicComponents} from '@/plugins/w/ionic'
registerIonicComponents(app)

//
import CmfInput from '@/plugins/cmf/cmf-input/cmf-input.vue'
import CmfSelect from '@/plugins/cmf/cmf-select/cmf-select.vue'
import CmfCheckbox from '@/plugins/cmf/cmf-checkbox/cmf-checkbox.vue'
import CmfDatePicker from '@/plugins/cmf/cmf-date-picker/cmf-date-picker.vue'
import CmfDropdown from '@/plugins/cmf/cmf-dropdown/cmf-dropdown.vue'
import CmfSearchDropdown from '@/plugins/cmf/cmf-search-dropdown/cmf-search-dropdown.vue'

app.component('cmf-input', CmfInput)
app.component('cmf-select', CmfSelect)
app.component('cmf-checkbox', CmfCheckbox)
app.component('cmf-date-picker', CmfDatePicker)
app.component('cmf-dropdown', CmfDropdown)
app.component('cmf-search-dropdown', CmfSearchDropdown)

// TAILWIND
import './plugins/app/_theme/tailwind.css'


// PRIME VUE - components (drop, calendar)
import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons

import PrimeVue from 'primevue/config'
app.use(PrimeVue)

// STYLES
import './styles'


// Events
import WEmitter from '@/plugins/w/emitter'
WEmitter.init()
app.config.globalProperties.eventBus = WEmitter.eventBus


// Toast
import wToast from '@/plugins/w/toast'
app.config.globalProperties.$wToast = wToast


// Loader
import wLoader from '@/plugins/w/loader'
app.config.globalProperties.$wLoader = wLoader

// Auto logout
import '@/plugins/fnb@utils/state-change.util'

// Refresh token
import '@/plugins/w/authStore/axiosTokenRefresh'


// WEBSOCKETS
declare global {
  interface Window {
    Pusher: any;
    Echo: any;
  }
}

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_WEBSOCKETS_KEY,
  wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
  wsPort: 6001,
  wssPort: 6001,
  disableStats: true,
  forceTLS: true,
  enabledTransports: ['ws', 'wss']
})

// MOCKS
// import moxios from '@/plugins/w/axios-mocks'

// moxios.mock({
//   'POST v1/auth/login': true,
//   'POST v1/auth/signup': true,
//   'POST v1/bank/widget-connect': true,
//   'GET v1/bank/accounts': true,
//   'POST v1/proxy/pfm/file-upload': true,
//   'POST v1/proxy/pfm/cash/account': true,
//   'GET v1/proxy/pfm/api/financialstatements/monthly': true,
//   'GET v1/proxy/pfm/config/get.action': true,

//   // 'GET v1/strandsmock/accounts/get/all.action': true,
//   // 'GET v1/strandsmock/budgetgoals/get/all.action': true,
//   // 'GET v1/strandsmock/categories/get/all.action': true,
//   // 'GET v1/strandsmock/api/financialcalendar/yearly': true,
//   // 'GET v1/strandsmock/financialcalendar/periodicpayment/get/all.action': true,
//   // 'GET v1/strandsmock/api/financialstatements/period': true,
//   // 'GET v1/strandsmock/api/financialstatements/period2': true,
//   // 'GET v1/strandsmock/api/financialstatements/summary': true,
//   // 'GET v1/strandsmock/api/insights/notifications': true,
//   // 'GET v1/strandsmock/savingsgoals/get.action': true,
//   // 'GET v1/strandsmock/api/transactions': true,
//   // 'GET v1/strandsmock/api/transactions2': true,
//   // 'GET v1/strandsmock/analysis/expenses/get.action': true,
// })

// TRANSLATIONS
import i18n  from '@/plugins/w/translate/index.js'
import  Translations  from  '@/plugins/w/translate/translations'
Translations.setup('sk', { // default language
  sk: 'Slovenský',
  pl: 'Polskie',
  en: 'English',
  cs: 'Česky',
  hr: 'Hrvatski'
})
setTimeout(() => {
  if (localStorage.getItem('language')) {
    return Translations.setLocale(localStorage.getItem('language'))
  }
  Translations.setLocale('sk')
}, 200)

app.use(i18n)

declare global {
  interface Window { strands: any }
}

router.isReady().then(() => {
  const strandsVendorScript = document.createElement('script')
  strandsVendorScript.setAttribute('src', 'strands-vendor.min.js')
  document.head.appendChild(strandsVendorScript)

  if (!window.strands) {
    const interval = setInterval(() => {
      if (window.strands) {
        clearInterval(interval)
        app.mount('#vueapp')
      } else {
        const pfmScript = document.createElement('script')
        pfmScript.setAttribute('src', 'pfm.min.js')
        document.head.appendChild(pfmScript)
      }
    }, 500)
  }
})
