import loginGuardIfMetaSet from '@/plugins/w/authStore/loginGuardIfMetaSet'

export default (title, path, component, requiresAuth = false) => {
  return {
    path,
    meta: { title, requiresAuth: requiresAuth },
    component: component,
    beforeEnter: loginGuardIfMetaSet
  }
}
