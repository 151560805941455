import { Exponea } from 'capacitor-plugin-exponea'
import { Device } from '@capacitor/device'
import { Capacitor } 			from '@capacitor/core'

// import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency'

class ExponeaTracking {

    // async isTrackingAllowed() {
    //     let trackingAllowed = true
        // const deviceInfo = await Device.getInfo()
        // if (deviceInfo.manufacturer === 'Apple') {
        //     const userInput = await AppTrackingTransparency.requestPermission()
        //     trackingAllowed = userInput.status === 'authorized'
        // }
    //
    //     console.log('TRACKING INFO', trackingAllowed)
    //
    //     return trackingAllowed && process.env.VUE_APP_EXPONEA_ALLOW_TRACKING == 'true'
    // }

    async init() {
        if (!Capacitor.isNativePlatform()) {
            return
        }
        const config = {
            projectToken: process.env.VUE_APP_EXPONEA_PROJECT_TOKEN,
            authorizationToken: process.env.VUE_APP_EXPONEA_AUTH_TOKEN,
            baseUrl: process.env.VUE_APP_EXPONEA_API_URL,
            pushTokenTrackingFrequency: 'EVERY_LAUNCH',
            visits: true,
            ios: {
                appGroup: 'group.eu.finbot.app'
            },
            automaticSessionTracking: true
        }
        console.log('EXPONEA INIT')
        console.log(config)

        Exponea.configure(config)
            .then(() => {
                Exponea.setLogLevel('OFF')
                Exponea.flushData()
            })
            .catch((err) => console.error(err))
    }

    async identifyCustomer(registered, email, extraProps = null) {
        let props = { }
        if ( extraProps ) {
            props = extraProps
        }
        props.email = email
        await Exponea.identifyCustomer({ registered }, props)
    }

    _exponeaIdentify(user) {
        if (Capacitor.isNativePlatform()) {
            this.identifyCustomer(user.id.toString(), user.email,
                {
                    firstname: user.name,
                    surname: user.surname,
                    language: user.profile?.language,
                    email: user.email
                })
            return
        }
        // return
        // window.exponea.identify({
        //     registered: user.email.toLowerCase().trim()
        // });
    }

    async flushData() {
        await Exponea.flushData()
    }
    trackWeb(eventType, payload = {}) {
        const lang = 'sk'
        const appVersion = process.env.VUE_APP_VERSION || '2.0.0'
        try {
            switch (eventType) {
                case 'app_login':
                case 'app_paired':
                    payload = {
                        device: 'web',
                        OS: 'web',
                        osVersion: 'web',
                        lang,
                        appVersion,
                        ...payload
                    }
                    window.exponea.track(eventType, payload)
                    break
                default:
                    window.exponea.track(eventType, payload)
            }
        } catch (err) {
                console.error('ERROR',err)
        }
    }

    async trackNative(eventType, payload = {}) {
        const deviceInfo = await Device.getInfo()
        const lang = 'sk'
        const appVersion = process.env.VUE_APP_VERSION || '2.0.0'

        try {
            switch (eventType) {
                case 'app_login':
                case 'app_paired':
                    payload = {
                        device: deviceInfo.model,
                        OS: deviceInfo.operatingSystem,
                        osVersion: deviceInfo.osVersion,
                        lang,
                        appVersion,
                        ...payload
                    }
                    await Exponea.trackEvent(eventType, payload)
                    break
                default:
                    await Exponea.trackEvent(eventType, payload)
            }

        } catch (err) {
            console.error('ERROR',err)
        }
    }

    async track(eventType, payload = {}) {
        if (Capacitor.isNativePlatform()) {
            await this.trackNative(eventType, payload)
            return
        }

        this.trackWeb(eventType, payload)
    }

}

export default new ExponeaTracking()
