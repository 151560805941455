import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2'
import { productsToRegister } from './productConfig'
import wAxios from '@/plugins/w/axios'
import WEmitter from '@/plugins/w/emitter'
import store from '@/store'



class WezeoInAppPurchase {
    public products: IAPProduct[] = []

    public init() {
        if(!Capacitor.isNativePlatform())
            return

        this._setupDebug()
        this._registerProduct()
        this._setupListeners()
        this._isInAppPurchaseStoreReady()
    }

    private _registerProduct(configuredProducts = productsToRegister) {
        for(let product of configuredProducts) {
            InAppPurchase2.register(product)
        }

        this.refreshStore()
    }

    private _setupListeners() {
        InAppPurchase2.when('product')
        .approved((product: IAPProduct) => {
            return product.verify()
        })
        .verified((product: IAPProduct) => {
            this._verifyReceipt(product)

            // TIP: if product.finish() is not called, subscription errors out in store
            // and you will be locked on /subscription screen with weird error
            // think about calling this conditionally if this._verifyReceipt() returns err
            return product.finish()
        }).finished((product: IAPProduct) => {
            // @ts-expect-error: Property 'emit' does not exist on type '{}'.ts(2339)
            WEmitter.eventBus.emit('inAppPurchase')
        }).cancelled(() => {
            // Runs when store pop up was closed by user
            console.log("CANCELED")
        }).error(()=> {
            console.log("ERR")
        })
    }

    private _isInAppPurchaseStoreReady() {
        InAppPurchase2.ready(() => {
            this.products = InAppPurchase2.products.filter(p => p.type != 'application')
        })
    }

    private _setupDebug() {
        // TODO: run only if not production
        if (process.env.VUE_APP_ENVIRONMENT == 'dev') {
            return
        }
        InAppPurchase2.verbosity = InAppPurchase2.DEBUG
    }

    private async _verifyReceipt(product) {
        if (!store.getters['wAuth/isLoggedIn']) {
            return
        }

        // TEMP FIX - refactor later
        if (store.getters['wAuth/user']?.profile?.is_subscribed) return

        const { platform } = await Device.getInfo()
        const transactionPayload = product

        if(platform === "android") {
            try {
                const data  = await wAxios.post_auth('v1/payment-validation/google-play', transactionPayload)
            } catch (error) {
                console.error(error)
            }
        }
        
        if(platform === "ios") {
            try {
                await wAxios.post_auth('v1/payment-validation/itunes', transactionPayload)
            } catch (error) {
                console.error(error)
            }
        }

        await store.dispatch('wAuth/userInfo')
    }


    public purchaseProduct(productID) {
        InAppPurchase2.order(productID)
        .then((product: IAPProduct) => {
            console.log("purchase activated")
        }, error => {
            alert(`Failed to purchase: ${error}`)
            this.refreshStore()
        })
    }

    public refreshStore() {
        InAppPurchase2.refresh()
    }

    // Opens app/playstore subscription settings
    public manageSubscriptions() {
        InAppPurchase2.manageSubscriptions()
    }
}

export default new WezeoInAppPurchase()
