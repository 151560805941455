import { createI18n } from 'vue-i18n'
import Translations from './translations.ts'


export const i18n = new createI18n({
	locale: Translations.getLocale(),
	fallbackLocale: Translations.getDefaultLocale(),
	messages: Translations.loadLocaleMessages(),
	missing: Translations.missingTranslation,
	postTranslation: Translations.postTranslation,
	silentTranslationWarn: true,
	preserveDirectiveContent: false
})

export default i18n
