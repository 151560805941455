import VueGtag from 'vue-gtag'
import { event } from 'vue-gtag'

export default {

    setupGoogleAnalytics(app, router = undefined) {
        if (router) {
            app.use(VueGtag, {
                config: {
                    id: process.env.VUE_APP_GA_KEY,
                },
            }, router)
            return
        }
        app.use(VueGtag, {
            config: { id: process.env.VUE_APP_GA_KEY }
        })
    },

    trackEvent(key, data) {
        event(key, data)
    }

}
