import skLang from './lang/sk.json'

function translate(sentence) {
	if (skLang[sentence]) {
		return skLang[sentence]
	} else if (typeof sentence == 'object') {

		Object.keys(sentence).forEach(key => {
			sentence[key].forEach(err => {
				if (skLang[err]) {
					const index = sentence[key].indexOf(err)
					if (index !== -1) {
						sentence[key][index] = skLang[err]
					}
				}
			})
		})
		return sentence
	}
	else {
		return sentence
	}
}

export default {
	parseToErrors(data) {
		let errorsArray = this._parseToArrayOfStrings(data)
		return errorsArray.map(error => error)
	},
	_parseToArrayOfStrings(data) {
		if (typeof data == 'string') {
			return [translate(data)]
		}

		if (data && data.error && typeof data.error == 'string') {
			return [translate(data.error)]
		}

		if (data && data.error && typeof data.error == 'object') {
			return Object.values(translate(data.error))
		}

		if (data.data && data.data.error && typeof data.data.error == 'string') {
			return [translate(data.data.error)]
		}

		if (data && data.data && data.data.error && typeof data.data.error == 'object') {
			return Object.values(translate(data.data.error))
		}

		if (data.statusText) {
			return [translate(data.statusText)]
		}

		return [data]
	}

}
