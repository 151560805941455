import { Capacitor } from '@capacitor/core'
import { Storage } from '@capacitor/storage'

export const routeRedirect = async (to, from, next) => {
  let redirectRoute = '/auth/login'

  if(!Capacitor.isNativePlatform()) {
    next(redirectRoute)
    return
  } 

  const { value } = await Storage.get({ key: 'biometricAuthAllowed' })
  redirectRoute = JSON.parse(value) ? '/auth/pin' : '/auth/login'

  next(redirectRoute)
}