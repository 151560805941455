<template>
  <div class="mb-4">
    <div class="ion-no-padding ion-no-margin cmf-input-wrapper relative ion-text-left" >
      <ion-label class="text-s">{{label}}</ion-label>
      <ion-input
          v-model="fieldData"
          :disabled="readonly"
          :name="name"
          :type="inputType"
          :placeholder="placeholder"
          class="ion-no-margin custom-input"
          @wheel="$event.target.blur()"
          @ionChange="changed($event)"/>
      <div class="toggle-password absolute z-20" v-if="togglePassword">
        <ion-icon @click="inputType = 'text' "     v-if="inputType == 'password'" :icon="icons.eyeOutline"></ion-icon>
        <ion-icon @click="inputType = 'password' " v-else                          :icon="icons.eyeOffOutline"></ion-icon>
      </div>
      <div v-if="(v$ && v$[form][name].$dirty ) || (vBe && vBe[name])">
        <img v-if="!hasError" src="../_assets/img/check-input.svg" alt="">
        <img v-if="hasError" src="../_assets/img/x-input.svg" alt="">
      </div>
    </div>
    <div class="error py-1" v-if="v$ && v$[form][name].$invalid && v$[form][name].$dirty">
      <div v-for="(error, index) in v$[form][name].$errors" :key="index">
        {{$t(validationErrors[error.$validator])}}
      </div>
    </div>
    <div class="error py-1" v-if="vBe && typeof vBe == 'object'">
      <div v-for="(error, index) in vBe[name]"  :key="index">
        {{displayError(error)}}
      </div>
    </div>
  </div>
</template>

<script>
import {eyeOutline, eyeOffOutline } from 'ionicons/icons'
import utils from '@/plugins/w/custom-toast/utils'
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    label: {
      required: false,
      default: ''
    },
    name: {
      required: true,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    togglePassword: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    vBe: {
      required: false,
    },
    form: {
      type: String,
      default: 'form'
    },
    v$: {
      default: null
    },
  },
  watch: {
    modelValue(data) {
      this.fieldData = data
    }
  },
  computed: {
    hasError() {
      if ( this.vBe && this.vBe[this.name]) {
        return true
      } else if (this.v$ && this.v$[this.form][this.name].$dirty && this.v$[this.form][this.name].$invalid) {
        return true
      }

      return false
    }
  },
  data(){
    return{
      icons: {
        eyeOutline,
        eyeOffOutline
      },
      inputType: '',
      fieldData: '',
      validationErrors: {
        required: 'Toto pole je povinné.',
        email: 'Zadaný email nie je v správnom tvare.',
        numeric: 'Hodnota poľa musí byť číslo.',
        minlength: `Toto pole obsahuje príliš málo znakov.`,
        maxLength: `Toto pole obsahuje príliš veľa znakov.`,
        sameAsPassword: 'Heslá sa nezhodujú.',
        agreed: 'Pre pokračovanie je potrebný súhlas s podmienkami.'
      }
    }
  },
  mounted() {
    this.inputType = this.type
    this.fieldData = this.modelValue
  },
  methods: {
    changed(e) {
      this.eventBus.emit('input', this.name)
      this.$emit('update:modelValue', e.target.value)
    },
    displayError(error) {
      return utils.parseToString(error)
    }
  }
}
</script>

<style lang="sass" scoped>

.cmf-input-wrapper
  img
    position: absolute
    right: 10px
    bottom: 12px
    z-index: 20
  .toggle-password
    right: 30px
    bottom: 5px


ion-input
  border: 1px solid #DCE1E9
  border-radius: 3px
  text-align: left
  margin-top: 5px
.has-focus
  border: 1px solid #0080F7


ion-label
  text-align: left

.custom-input
  --padding-start: 10px


</style>
