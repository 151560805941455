import store from '@/store'
import { hasActiveSubscription } from '@/plugins/w/in-app-purchases/subscribed.guard'
import {Capacitor} from '@capacitor/core'

export default async function(to, from, next) {
	let isSubscribed = true
	if (Capacitor.isNativePlatform()) {
		isSubscribed = hasActiveSubscription()
	}

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.getters['wAuth/isLoggedIn']) {
			next('/auth/login')
			return
		}

		if(!isSubscribed) {
			next('/user/subscribe')
			return
		}
	}

	return next()
}
