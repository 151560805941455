import mitt from 'mitt'

class Emitter {
    // eslint-disable-next-line @typescript-eslint/ban-types
    eventBus: {}
    async init() {
        this.eventBus = mitt()
    }
}
export default new Emitter()
