import axios from 'axios'
import wAxios from '@/plugins/w/axios'
import store from '@/store'
import WEmitter from '@/plugins/w/emitter'
import createAuthRefreshInterceptor  from 'axios-auth-refresh'


const REFRESH_TOKEN_URL = 'v1/auth/refresh'

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => 
    wAxios.post_auth_data(REFRESH_TOKEN_URL)
    .then((tokenRefreshResponse) => {
        const { user, token } = tokenRefreshResponse.data.response
        
        handleAuthStoreCommits(token, user)
        failedRequest.response.config.headers['Authorization'] = `Bearer ${token}`
        emitTokenRefreshEvents()

        return Promise.resolve()
    }).catch(error => handleRefreshError(error))


// Updates vuex store
const handleAuthStoreCommits = (token, user) => {
    const payload = {token, user}
    store.commit('wAuth/auth_success', payload)
}

// 
const emitTokenRefreshEvents = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    WEmitter.eventBus.emit('tokenRefreshed')
}

const handleRefreshError = (error) => {
    window.open(location.origin, "_self")
	store.commit('wAuth/auth_error')
	console.error(error)
}

// Core logic
createAuthRefreshInterceptor(axios, refreshAuthLogic)
