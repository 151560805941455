import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2'

const MONTHLY_SUBSCRIPTION_PRODUCT_ID = 'monthly_subscription'
const YEARLY_SUBSCRIPTION_PRODUCT_ID = 'yearly_subscription'

export const productsToRegister = [
    {
        id: MONTHLY_SUBSCRIPTION_PRODUCT_ID,
        type: InAppPurchase2.PAID_SUBSCRIPTION,
    },
    {
        id: YEARLY_SUBSCRIPTION_PRODUCT_ID,
        type: InAppPurchase2.PAID_SUBSCRIPTION,
    }
]
