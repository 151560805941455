import * as IdleJs 	from 'idle-js/dist/Idle.js'
import store 		from '@/store'
import router 		from '@/router'

export default {

	init(idleTime = 60000*10) {

		let idle = new IdleJs({
			idle: idleTime,
			onIdle: async () => {
				if ( router.currentRoute.value.fullPath == '/auth/login') {
					return
				}

				await store.dispatch('wAuth/logout')
				window.open(location.origin, '_self')
				this.$router.push('/')
				},
			})
		idle.start()
	},
}