import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
} from '@capacitor/push-notifications'
import { FCM } from '@capacitor-community/fcm'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import axios from '../w/axios'

class Notifications {

    private _token = ''

    public init() {
        if (!Capacitor.isNativePlatform())
            return

        this._requestPermission()
        this._listeners()
    }

    // public async getToken() {
    //     try {
    //         const { token } = await FCM.getToken()
    //         return token
    //     } catch (err) { return Promise.reject(err) }
    // }

    public async addDevice() {
        const firebase_token = this._token
        const deviceInfo = await Device.getInfo()
        const deviceID = await Device.getId()


        const options = {
            firebase_token,
            ...deviceInfo,
            ...deviceID,
            app_version: process.env.VUE_APP_VERSION
        }

        try {
            await axios.post_auth(`v1/auth/devices/register`, options )
        } catch (err) {
            console.log(err)
        }
    }

    async subscribeTopic(topic, newLang?) {
        const lang = newLang || 'sk'
        try {
            const res = await FCM.subscribeTo({ topic: lang + '_' + topic })
            return Promise.resolve(res)
        } catch (err) {
            console.error(err)
            return Promise.reject(err)
        }
    }

    private _requestPermission() {
        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register()
            } else {
                // Show some error
                alert('error notif')
            }
        })
    }

    private _listeners() {
        PushNotifications.addListener('registration',
            async (token: Token) => {
                this._token = token.value

                if (Capacitor.getPlatform() === 'ios') {
                    const { token: fcm_token } = await FCM.getToken()
                    this._token = fcm_token
                }
            }
        )
        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                alert('Error on registration: ' + JSON.stringify(error))
            }
        )

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                alert('Push received: ' + JSON.stringify(notification))
            }
        )

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                alert('Push action performed: ' + JSON.stringify(notification))
            }
        )
    }
}

export default new Notifications()
