import { Network } from '@capacitor/network'
import router from '@/router'
import { Capacitor } from '@capacitor/core'

let lastPath = ''
let timeout
let offline = false

/*
  when android app is minimized and then maximized or phone is locked, the networkStatusChange event is called
  with status status.connected = true (without following event it redirects app to the /guest/news screen).
*/
document.addEventListener('pause', () => {
  lastPath = router.currentRoute.value.fullPath
}, false)

export async function getNetworkStatus() {
  const status = await Network.getStatus()
  return { ...status, lastPath }
}

async function detectNetworkConnection() {
  Network.addListener('networkStatusChange', (status) => {

    if (!status.connected) {
      lastPath = router.currentRoute.value.fullPath
      timeout = setTimeout(() => {
        offline = true
        
        if (!Capacitor.isNativePlatform()) return

        router.push({ path: '/auth/offline' })
      }, 3000)
    } else {
      if (offline) {
        offline = false
        router.push({ path: ( !lastPath || lastPath === '/auth/offline' ) ? '/auth/login' : lastPath })
        return
      } else {
        clearTimeout(timeout)
      }
    }
  })

  const status = await getNetworkStatus()

  if (!status.connected) {
    if (!Capacitor.isNativePlatform()) return

    router.push({ path: '/auth/offline' })
  }
}

detectNetworkConnection()
