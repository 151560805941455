<template>
  <div class="mb-4" :class="sidebar ? 'gray' : ''">
    <p class="mb-2 text-s text-left" v-if="label">{{ label }}</p>
    <div class="relative">
      <Multiselect
          v-model="fieldData"
          :options="options"
          :searchable="true"
          :canDeselect="false"
          :placeholder="placeholder"
          :disabled="disabled"
          :caret="false"
          :noResultsText="$t('Nenašli sa žiadne výsledky')"
      />
      <span class="pi pi-chevron-down absolute top-3 right-3"></span>
    </div>
  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import utils from '@/plugins/w/custom-toast/utils'
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    label: {
      required: false,
      default: ''
    },
    placeholder: {
      required: false,
      default: ''
    },
    disabled: {
      required: false,
      default: false
    },
    options: {
      required: false,
      default: []
    },
    v$: {
      default: ''
    },
    form: {
      type: String,
      default: 'form'
    },
    name: {
      required: true,
      default: ''
    },
    sidebar: {
      required: false,
      default: true
    },
  },
  components: {
    Multiselect
  },

  mounted() {
    this.fieldData = this.modelValue
  },
  watch: {
    modelValue(data) {
      this.fieldData = data
    },

    fieldData(data) {
      this.$emit('update:modelValue', data)
    }
  },
  data(){
    return{
      fieldData: null,
      validationErrors: {
        required: 'Toto pole je povinné.',
      }
    }
  },
  computed: {
    hasError() {
      if (this.v$ && this.v$[this.form][this.name].$dirty && this.v$[this.form][this.name].$invalid) {
        return true
      }

      return false
    }
  },
  methods: {
    changed(e) {
      this.$emit('update:modelValue', e.target.value)
    },
    displayError(error) {
      return utils.parseToString(error)
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="sass">
ul li.multiselect-option
  color: #858585 !important
  font-size: 14px
  &:hover
    color: #495057
ul li.multiselect-option.is-selected
  background: #E3F2FD !important
  color: #495057
.multiselect-single-label,
.multiselect-search
  padding-left: 10px
ul li.multiselect-option.is-selected.is-pointed
  color: #495057
.multiselect-clear
  display: none
.multiselect
  border: 1px solid #DCE1E9
  border-radius: 3px
  height: 41px !important
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s
  &:hover
    border-color: #2196F3
.multiselect.is-active
  box-shadow: none
.multiselect-dropdown
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)
</style>
<style lang="sass" scoped>
span
  color: #6c757d
div
  font-size: 0.875rem
div.gray
  color: rgba(133, 133, 133, 1)
</style>
