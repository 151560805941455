<template>
  <div class="mb-4">
    <label class="text-left">
      <p class="mb-2 text-s" v-if="label">{{ label }}</p>
      <label v-if="title" class="text-s">{{ title }}</label>
      <Dropdown :class="noSecondWalletError ? 'error-select' : ''" class="w-full" v-model="fieldData" :options="options" :placeholder="placeholder" :disabled="disabled" :loading="loading">
        <template #value="slotProps">
          <div v-if="slotProps.value">
            <img v-if="showImage" class="inline mr-2" :alt="slotProps.value.name" :src="require(`./_icons/${slotProps.value.img}`)"/>
            <span class="text-descgray-sidebar text-sm align-middle">{{$t(slotProps.value.name)}}</span>
          </div>
          <span class="text-sm" v-else>
            {{slotProps.placeholder}}
          </span>
        </template>
        <template #option="slotProps">
          <div>
            <img v-if="showImage" class="inline mr-2" :alt="slotProps.option.name" :src="require(`./_icons/${slotProps.option.img}`)" />
            <span class="text-descgray-sidebar text-sm">{{$t(slotProps.option.name)}}</span>
          </div>
        </template>
      </Dropdown>
    </label>

    <div class="error py-1" v-if="v$ && v$[form][name].$invalid && v$[form][name].$dirty">
      <div v-for="(error, index) in v$[form][name].$errors" :key="index">
        {{$t(validationErrors[error.$validator])}}
      </div>
    </div>
  </div>
</template>
<script>

import Dropdown 		from 'primevue/dropdown'
import utils 				from '@/plugins/w/custom-toast/utils'

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: Object,
    label: {
      required: false,
      default: ''
    },
    sidebar: {
      default: false
    },
    placeholder: {
      required: false,
      default: ''
    },
    disabled: {
      required: false,
      default: false
    },
    options: {
      required: false,
      default: []
    },
    v$: {
      default: ''
    },
    form: {
      type: String,
      default: 'form'
    },
    name: {
      required: true,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    showImage: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
			required: false
    },
		noSecondWalletError: {
			type: Boolean
		}

  },
  components: {
    Dropdown
  },

  mounted() {
    this.fieldData = this.modelValue
  },
  watch: {
    modelValue(data) {
      this.fieldData = data
    },

    fieldData(data) {
      this.$emit('update:modelValue', data)
    }
  },
  data(){
    return{
      fieldData: '',
      validationErrors: {
        required: 'Toto pole je povinné.',
      }
    }
  },
  computed: {
    hasError() {
      if (this.v$ && this.v$[this.form][this.name].$dirty && this.v$[this.form][this.name].$invalid) {
        return true
      }

      return false
    }
  },
  methods: {
    changed(e) {
      this.$emit('update:modelValue', e.target.value)
    },
    displayError(error) {
      return utils.parseToString(error)
    }
  }
}
</script>
<style lang="sass" scoped>
.p-dropdown
  height: 41px !important
.p-dropdown
  border: 1px solid #DCE1E9
.error-select
  border: 1px solid red !important
</style>
