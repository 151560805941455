<template>
  <div>
    <div class="error error-top" v-if="v$ && v$[form][name].$invalid && v$[form][name].$dirty && up">
      <div v-for="(error, index) in v$[form][name].$errors" :key="index">
        {{$t(validationErrors[error.$validator])}}
      </div>
    </div>
    <ion-item lines="none"  class="ion-no-padding ion-no-margin ion-margin-vertical items-start relative ion-text-left" >

      <ion-checkbox
          mode="md"
          v-model="fieldData"
          :disabled="readonly"
          @ionChange="changed($event)">
      </ion-checkbox>
      <p class="small" @click="labelClicked">
         <slot></slot>
      </p>
    </ion-item >
    <div class="error" v-if="v$ && v$[form][name].$invalid && v$[form][name].$dirty && !up">
      <div v-for="(error, index) in v$[form][name].$errors" :key="index">
        {{$t(validationErrors[error.$validator])}}
      </div>
    </div>

    <div class="error" v-if="vBe">
      <div v-for="(error, index) in vBe[name]"  :key="index">
        {{displayError(error)}}
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/plugins/w/custom-toast/utils'

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    up: {
      required: false,
      default: false
    },
    name: {
      required: true,
      default: ''
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    vBe: {
      type: Array,
      required: false,
    },
    form: {
      type: String,
      default: 'form'
    },
    v$: {
      default: null
    },
  },
  mounted() {
    this.fieldData = this.modelValue
  },
  watch: {
    modelValue(data) {
      this.fieldData = data
    }
  },
  computed: {
    hasError() {
      if ( this.vBe && this.vBe[this.name]) {
        return true
      } else if (this.v$ && this.v$[this.form][this.name].$dirty && this.v$[this.form][this.name].$invalid) {
        return true
      }

      return false
    }
  },
  data(){
    return{
      fieldData: false,
      validationErrors: {
        required: 'Toto pole je povinné.',
        booleanRequired: 'Tento súhlas je povinný',
        mustBeTrue: 'Pre pokračovanie musíš súhlasiť so zásadami ochrany osobných údajov.',
      }
    }
  },
  methods: {
    changed(e) {
      this.eventBus.emit('input', this.name)
      this.$emit('update:modelValue', e.target.checked)
    },
    displayError(error) {
      return utils.parseToString(error)
    },

    labelClicked() {
      this.fieldData = !this.fieldData
    }
  }
}
</script>

<style lang="sass" scoped>
ion-checkbox
  margin-right: 10px
  position: relative
  min-width: 18px
  margin-top: 0
  top: 4px
ion-item
  --background-hover: none
ion-label
  position: relative
  top: 6px
.small
  font-size: 14px
  color: #707070
.error-top
  position: relative
  top: 15px
</style>
