import inAppPurchase from '@/plugins/w/in-app-purchases'
import { Capacitor } from '@capacitor/core'
import store from '@/store'

export const hasActiveSubscription = () => {
  const isSubscribed =  isSubscribedOnBE() || isDesktop() || isSubscribedInVendorStore()

  return isSubscribed
}

// Has active subscription in store of current device (ios/android)
const isSubscribedInVendorStore = () => {
  let isSubscribed = false

  inAppPurchase.products.forEach(product => {
    //NOTE: consider adding -> && !product.canPurchase
    const hasSubscription = product.owned && product.state === 'owned' 

    if (hasSubscription) {
      isSubscribed = true
    }
  })

  return isSubscribed
}

const isSubscribedOnBE = () => {
  const isSubscribed = store.getters['wAuth/user']?.profile?.is_subscribed
  return !!isSubscribed
}

const isDesktop = () => {
  return !Capacitor.isNativePlatform()
}
