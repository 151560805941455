
declare global {
  interface Window { strands: any }
}

import { mapGetters } from 'vuex'
import { Capacitor } from '@capacitor/core'
import browserId from "browser-id"
import { StatusBar, Style } from '@capacitor/status-bar'
import IdleJs from '@/plugins/w/idleJs'


export default {

  computed: {
    ...mapGetters('wAuth', ['user']),
  },

  async created() {

    const id = browserId()
    localStorage.setItem('device_id', id)

	if (Capacitor.getPlatform() == 'android') {
		await StatusBar.setStyle({ style: Style.Dark })
		return
	}

    if (Capacitor.isNativePlatform()) {
      await StatusBar.setStyle({ style: Style.Light })
      return
    }

	IdleJs.init()

  },

  mounted() {
    if (this.user?.profile?.language) {
      localStorage.setItem('language', this.user?.profile?.language)
    }
  }
}
