import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { routeRedirect } from '@/plugins/fnb@utils/router.utils'
import wRoute from '@/plugins/w/w-route'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    // redirect: '/auth/login',
    component: () => import('./plugins/app@account/login/login.vue'), // This serves just as a placeholder
    beforeEnter: routeRedirect
  },
  {
    path: '/auth',
    component: () => import('./plugins/app/_layout/public/default.vue'),
    props: { back: false, languageVisible: true },
    children: [
      wRoute('Login',                       'login',                     () => import('./plugins/app@account/login/login.vue')),
      wRoute('Register',                    'register',                  () => import('./plugins/app@account/register/register.vue')),
      wRoute('Demographics',                'demographics',              () => import('./plugins/app@onboarding/demographics/demographics.vue')),
      wRoute('Skip demographics',           'skip-demographics',         () => import('./plugins/app@onboarding/demographics/skip-demographics.vue')),
      wRoute('Activation',                  'activation',                () => import('./plugins/app@account/activation/activation.vue')),
      wRoute('Forgot',                      'forgot-password',           () => import('./plugins/app@account/forgot-pwd/forgot-pwd.vue')),
      wRoute('Reset',                       'reset-password',            () => import('./plugins/app@account/reset-pwd/reset-pwd.vue')),
      wRoute('Browser verification',        'verify-browser',            () => import('./plugins/lib@user/browser-verification/browser-verification.vue')),
      wRoute('Login verification',          'verify-login',              () => import('./plugins/lib@user/login-verification/login-verification.vue')),
      wRoute('Maintenance',                 'maintenance',               () => import('./plugins/lib@screen/maintenance/maintenance.vue')),
      wRoute('Offline',                     'offline',                   () => import('./plugins/lib@screen/offline/offline.vue')),
      wRoute('404',                         'not-found',                 () => import('./plugins/lib@screen/404/404.vue')),
      wRoute('Pin',                         'pin',                         () => import('./plugins/app@account/pin/pin.vue')),
      wRoute('Create pin',                  'pin-create',                  () => import('./plugins/app@account/pin/pin-creation.vue')),
      wRoute('Biometric auth confirmation', 'biometric-auth-confirmation', () => import('./plugins/app@account/biometric-auth-confirmation/biometric-auth-confirmation.vue')),
    ]
  },
  {
    path: '/user',
    component: () => import('./plugins/app/_layout/public/default.vue'),
    props: { back: false, languageVisible: false },
    children: [
      wRoute('Subscribe',                            'subscribe',                          () => import('./plugins/app@account/in-app-purchase/in-app-purchase.vue')),
    ]
  },
  {
    path: '/bank',
    component: () => import('./plugins/app/_layout/public/default.vue'),
    props: { back: true, languageVisible: false },
    children: [
      wRoute('BankChoose',                  'bank-choose',               () => import('./plugins/app@onboarding/bank-choose/bank-choose.vue'), true),
      wRoute('Currency',                    'currency',                  () => import('./plugins/app@onboarding/currency/currency.vue'), true),
      wRoute('CashBalance',                 'cash-balance',              () => import('./plugins/app@onboarding/cash-balance/cash-balance.vue'), true),
      wRoute('BankConsent',                 'bank-consent',              () => import('./plugins/app@trash/bank-consent/bank-consent.vue'), true),
      wRoute('PersonalData',                'personal-data',             () => import('./plugins/app@onboarding/personal-data/personal-data.vue'), true),
    ]
  },
  {
    path: '/app',
    component: () => import('./plugins/app/_layout/main/main.vue'),
    children: [
      wRoute('Dashboard',                   'dashboard',                 () => import('./plugins/app@strands/dashboard/dashboard.vue'), true),
      wRoute('Budgets',                     'budgets',                   () => import('./plugins/app@strands/budgets/budgets.vue'), true),
      wRoute('Cashflow',                    'cashflow',                  () => import('./plugins/app@strands/cashflow/cashflow.vue'), true),
      wRoute('Financialcalendar',           'financialcalendar',         () => import('./plugins/app@strands/financial-calendar/financial-calendar.vue'), true),
      wRoute('Financialanalysis',           'financialanalysis',         () => import('./plugins/app@strands/financial-analysis/financial-analysis.vue'), true),
      wRoute('Provisioning',                'provisioning',              () => import('./plugins/app@strands/provisioning/provisioning.vue'), true),
      wRoute('Transactions',                'transactions',              () => import('./plugins/app@strands/transactions/transactions.vue'), true),
      wRoute('Scheduled',                   'scheduled',                 () => import('./plugins/app@strands/scheduled/scheduled.vue'), true),
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/auth/not-found'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
